import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'; 
import logo from "./logo.png";

export default function Register() {
  const location = useLocation();
  const [values, setValues] = useState({});
  const [confirmValues, setConfirmValues] = useState({});
  const [error, setError] = useState("");
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const isConfirmStep = searchParams.get('isConfirmStep') === 'true';

    if (email) {
      setValues((prevValues) => ({ ...prevValues, email }));
    }
    setIsConfirmStep(isConfirmStep);
    }, [location.search]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const submit = async () => {
    try {
      values.email = values.email.toLowerCase();
      await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({ user: values })
      })
        .then(res => {
          return res.json();
        })
        .then(x => {
          setIsConfirmStep(true)
          if (x.error) {
            if (x.error == "email_in_use") {
              setError(
                "Email already in use. Please sign in or register with a different email."
              );
            } else {
              setError(
                "Registration failed. Please check your details and try again."
              );
            }
          }
        });
    } catch (err) {
      setError(err.message);
    }
  };

  const onConfirm = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/auth/verify-code`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({ email: values.email, code: confirmValues.confirmationCode })
      })
        .then(res => {
          return res.json();
        })
        .then(x => {
          setIsConfirmStep(true)
          if (x.error) {
            if (x.error == "email_in_use") {
              setError(
                "Email already in use. Please sign in or register with a different email."
              );
            }
          } else if (x.token) {
            localStorage.setItem("token", x.token);
            localStorage.setItem("user", JSON.stringify(x.user));
            window.location.replace(x.redirectUrl);
          } else {
            setError(
              "Registration failed. Please check your details and try again."
            );
          }
        });
    } catch (err) {
      setError(err.message);
    }
  }

  const resendCode = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/auth/resend-code`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({ email: values.email.toLowerCase() })
      })
        .then(res => {
          setError(
              "A new verification code has been sent to your email."
          );
          return res.json();
        })
        .then(x => {
          if (x.error) {
            setError(x.error)
          }
        });
    } catch (err) {
      setError(err.message);
    }
  }

  return (
    <>
      <div className="flex flex-col h-dvh bg-dark">
        <div className="flex flex-col items-center justify-center flex-1">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="w-64 h-auto mx-auto mb-10" src={logo} />
          </div>
          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
            {!isConfirmStep &&
              <div className="px-4 py-8 rounded shadow bg-neutral-700 sm:px-10">
                <div className="text-xl font-medium text-center text-white">
                  Create your account
                </div>
                <div className="my-5 text-sm font-light text-center text-light">
                  Already have an account?{" "}
                  <a
                    className="text-purple-300 hover:text-purple-200"
                    href="/login"
                  >
                    Sign In
                  </a>
                </div>
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-center text-white">
                      {error}
                    </label>
                  </div>

                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-white"
                    >
                      Name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        value={values.name}
                        onChange={e =>
                          setValues({ ...values, name: e.target.value })
                        }
                        id="name"
                        name="name"
                        autoComplete="name"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-white"
                    >
                      Email address <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        value={values.email}
                        onChange={e =>
                          setValues({ ...values, email: e.target.value })
                        }
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-white"
                      >
                        Password <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1 relative">
                        <input
                          value={values.password}
                          onChange={e =>
                            setValues({ ...values, password: e.target.value })
                          }
                          type={showPassword ? 'text' : 'password'}
                          required
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                          {showPassword ? (
                            <EyeSlashIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                          ) : (
                            <EyeIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                          )}
                        </button>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-white"
                      >
                        Confirm Password <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1 relative">
                        <input
                          value={values.confirmPassword}
                          onChange={e =>
                            setValues({
                              ...values,
                              confirmPassword: e.target.value
                            })
                          }
                          type={showPassword ? 'text' : 'password'}
                          required
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                          {showPassword ? (
                            <EyeSlashIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                          ) : (
                            <EyeIcon className="h-5 w-5 text-neutral-500" aria-hidden="true" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="flex">
                    <input
                      checked={values.tos}
                      onChange={e =>
                        setValues({ ...values, tos: e.target.checked })
                      }
                      type="checkbox"
                      className="block appearance-none rounded border mt-0.5 border-gray-300  placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                    />

                    <div className="ml-2 text-sm text-light">
                      I accept the platform{" "}
                      <a
                        href="/terms"
                        className="text-purple-200 hover:text-purple-300"
                      >
                        Terms of Service
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      onClick={() => submit()}
                      type="button"
                      disabled={
                        !values.email ||
                        !values.password ||
                        values.password != values.confirmPassword ||
                        !values.tos ||
                        !values.name
                      }
                      className="flex justify-center w-full px-4 py-2 font-semibold text-white bg-purple-500 border border-transparent rounded shadow-sm disabled:bg-gray-500 text-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              </div>
            }
            {isConfirmStep &&
              <div className="flex flex-col px-4 py-8 rounded shadow bg-neutral-700 sm:px-10 gap-5">
                <div className="text-xl font-medium text-center text-white mb-8">
                  Complete the sign-up process.
                </div>
                <div>
                  <label className="block text-sm font-medium text-center text-white">
                    {error}
                  </label>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-white"
                    >
                      Verification Code <span className="text-red-500">*</span>
                    </label>
                    <div className="block text-md font-bold text-white cursor-pointer" onClick={() => resendCode()}>Resend Code</div>
                  </div>
                  <div className="mt-1">
                    <input
                      value={confirmValues.confirmationCode}
                      onChange={e =>
                        setConfirmValues({ ...confirmValues, confirmationCode: e.target.value })
                      }
                      id="confirmationCode"
                      name="confirmationCode"
                      autoComplete="confirmationCode"
                      required
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    onClick={() => onConfirm()}
                    type="button"
                    disabled={
                      !confirmValues.confirmationCode
                    }
                    className="flex justify-center w-full px-4 py-2 font-semibold text-white bg-purple-500 border border-transparent rounded shadow-sm disabled:bg-gray-500 text-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
